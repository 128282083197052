import React from "react";
import styled from "styled-components/macro"; // eslint-disable-line no-unused-vars
import { WRITE_SETTINGS } from "context/permissions";
import { useFormModal } from "components/Modal";
import { OutlineButton } from "components/Button";
import { SettingsIcon } from "components/Icons";
import { TextField, NoteField } from "components/Forms";
import { useAgent } from "context/agentContext";
import { noop } from "utils";
import { deleteSetting } from "api/toolsWeb";

const testIdPrefix = "delete-setting-";

export const useDeleteSettingModal = () => {
  const { agent } = useAgent();
  const [FormModal, openModal] = useFormModal();

  const DeleteSettingModal = ({
    onSuccess = noop,
    prefix,
    settingsKey = "",
    settingsValue = "",
  }) => {
    if (!agent.hasPermissions([WRITE_SETTINGS])) {
      return <div data-testid={`${testIdPrefix}modal-no-permissions`}></div>;
    }

    const keyWithoutPrefix = settingsKey.substring(prefix.length);

    return (
      <FormModal
        actionIcon={<SettingsIcon size="1rem" />}
        actionText="Delete"
        formProps={{
          initialValues: {
            settingsKey: keyWithoutPrefix,
            settingsValue,
          },
        }}
        onSubmitActionSuccess={onSuccess}
        submitAction={() => deleteSetting(settingsKey, agent)}
        testIdPrefix={testIdPrefix}
        titleText={`Delete ${keyWithoutPrefix}`}
      >
        <div
          css={`
            min-height: 30vh;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          <TextField
            data-testid={"delete-settings-key-field"}
            name="settingsKey"
            label="Key"
            focus={true}
            required
            readOnly={true}
          />
          <NoteField
            data-testid="delete-settings-value-field"
            name="settingsValue"
            label="Value"
            readOnly={true}
          />
        </div>
      </FormModal>
    );
  };

  const DeleteSettingButton = ({ label = "Delete", ...props }) => {
    if (!agent.hasPermissions([WRITE_SETTINGS])) {
      return null;
    }
    return (
      <OutlineButton data-testid="delete-setting-button" {...props} onClick={openModal}>
        {label}
      </OutlineButton>
    );
  };

  return [DeleteSettingModal, DeleteSettingButton];
};
