import React, { createContext, useCallback, useContext, useMemo } from "react";
import * as R from "ramda";
import { useLocation } from "react-router-dom";
import { useAgentSettings } from "utils/useAgentSettings";
import { permissionsSatisfy, permissionsSatisfyAny, toolsPermissions } from "./permissions";
import { useIntegrator, useToken } from "context/integratorContext";
import {
  TILIA,
  debugFn,
  debugOff,
  formatDateTime,
  getLocQueryString,
  getTokenFromJWT,
  isBeforeNow,
  isProd,
} from "utils";
import { logout } from "api/toolsWeb";
import { useApolloClient } from "@apollo/client";

const AgentContext = createContext();

const debugToken = (location, token, agentSettings) => {
  const qs = getLocQueryString(location);
  if (R.includes("token", R.keys(qs))) {
    debugFn("token:", {
      ...token,
      expiration: formatDateTime(token.exp * 1000),
      expired: isTokenExpired(token),
      agentSettings,
    });
  }
};
const isTokenExpired = (token) => token && isBeforeNow(token.exp * 1000);

const AgentProvider = (props) => {
  const apolloClient = useApolloClient();
  const location = useLocation();
  const { sessionJWT, setToken, deleteToken } = useToken();

  const [agentSettings, setAgentSetting] = useAgentSettings(getTokenFromJWT(sessionJWT).account_id);
  const token = useMemo(() => getTokenFromJWT(sessionJWT), [sessionJWT]);
  debugToken(location, token, agentSettings);

  const tokenExpired = useCallback((token) => isTokenExpired(token), []);
  const integrator = useIntegrator(token.integrator);
  const params = new URLSearchParams(location.search);
  const includesDebug = params.has("__debug");
  const debug = useMemo(() => {
    if (!isProd() && (agentSettings?.debugMode || includesDebug)) {
      return debugFn;
    }
    return debugOff;
  }, [agentSettings?.debugMode, includesDebug]);

  const agent = useMemo(() => {
    const processResponseError = (responseError) => {
      debug(
        "[agentContext::processResponseError]",
        responseError.name,
        responseError.statusCode,
        responseError.loggingMessage
      );
      if (responseError.statusCode === 401) {
        logout(agent);
        apolloClient.resetStore();
        deleteToken();
      }
    };

    return sessionJWT
      ? {
          jwt: sessionJWT,
          ...token,
          debug,
          tokenExpiration: formatDateTime(token.exp * 1000),
          tokenExpired: () => tokenExpired(token),
          hasToolsPermissions: () => permissionsSatisfyAny(toolsPermissions, token.permissions),
          hasPermissions: (requiredPermissions, resource = "") => {
            return (
              integrator.accessResource(resource) &&
              permissionsSatisfy(requiredPermissions, token.permissions)
            );
          },
          hasPermissionsAny: (requiredPermissions, resource = "") => {
            return (
              integrator.accessResource(resource) &&
              permissionsSatisfyAny(requiredPermissions, token.permissions)
            );
          },
          isOwnAccount: R.equals(token.account_id),
          // "integrator" is spread in the ...token TODO: fix usages, then change this
          integratorConfig: integrator,
          isTilia: () => integrator?.integratorId === TILIA,
          processResponseError,
        }
      : null;
  }, [apolloClient, deleteToken, sessionJWT, token, tokenExpired, debug, integrator]);

  const logoutAgent = () => {
    logout(agent);
    apolloClient.resetStore();
    deleteToken();
  };
  const deleteAgent = logoutAgent;

  const setAgent = useCallback(
    (jwt) => {
      apolloClient.resetStore();
      setToken(jwt);
    },
    [setToken, apolloClient]
  );

  return (
    <AgentContext.Provider
      value={{ agent, setAgent, deleteAgent, logoutAgent, agentSettings, setAgentSetting, debug }}
      {...props}
    />
  );
};

const useAgent = () => useContext(AgentContext);

export { useAgent, AgentProvider };
