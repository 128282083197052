import * as R from "ramda";
import { isNilOrEmpty, debug } from "utils";
import { ctxWithIntegrator } from "utils/auth";
import {
  METHODS,
  MultiError,
  assertIntegrator,
  assertOk,
  mapProxyFetch,
  serviceMap,
} from "../utils";
import buildTiliaApiResponse from "../buildTiliaApiResponse";

export { useAddPermissionGroup } from "./useAddPermissionGroup";

const accountsPubProxy = mapProxyFetch(serviceMap.ACCOUNTS_API);
const accountsOpsProxy = mapProxyFetch(serviceMap.ACCOUNTS_OPS_API);

export const registerAccount = async (emailAddress, ctx) => {
  debug(ctx, "[registerAccount]", emailAddress);
  assertIntegrator(ctx);
  const response = await accountsPubProxy(ctx, `/register`, METHODS.POST, {
    username: emailAddress,
    email: {
      address: emailAddress,
    },
  });
  await assertOk(response, [200, 409]);
  return response.json();
};

export const blockAccount = async (accountId, capability, reason, note, ctx) => {
  debug(ctx, "[blockAccount]", accountId, capability, reason, note);
  assertIntegrator(ctx);
  const response = await accountsPubProxy(ctx, `/v2/block`, METHODS.POST, {
    account_id: accountId,
    block_capability: capability,
    block_reason: reason,
    note,
  });
  return buildTiliaApiResponse(response);
};

export const unblockAccount = async (accountId, capability, ctx) => {
  debug(ctx, "[unblockAccount]", accountId, capability);
  assertIntegrator(ctx);
  const response = await accountsPubProxy(
    ctx,
    `/v2/block/${encodeURIComponent(accountId)}/capability/${encodeURIComponent(capability)}`,
    METHODS.DELETE,
  );
  return buildTiliaApiResponse(response);
};

export const getAccountUserInfoAdmin = async (accountId, ctx) => {
  debug(ctx, "[getAccountUserInfoAdmin]", accountId);
  const response = await accountsOpsProxy(
    ctx,
    `/${encodeURIComponent(accountId)}/user-info/tilia-admin`,
  );
  await assertOk(response);
  return response.json();
};

export const getAccountUserInfo = async (accountId, ctx) => {
  debug(ctx, "[getAccountUserInfo]", accountId);
  //!!!!! assertIntegrator(ctx);
  const response = await accountsOpsProxy(ctx, `/${encodeURIComponent(accountId)}/user-info`);
  await assertOk(response, [404]);
  return response.json();
};

export const getAccountEmails = async (accountId, ctx) => {
  debug(ctx, "[getAccountEmails]", accountId);
  assertIntegrator(ctx);
  const response = await accountsOpsProxy(
    ctx,
    `/${encodeURIComponent(accountId)}/user-info/email/all`,
  );
  await assertOk(response);
  return response.json();
};

export const getAccountPermissions = async (accountId, ctx) => {
  debug(ctx, "[getAccountPermissions]", accountId);
  assertIntegrator(ctx);
  const response = await accountsOpsProxy(ctx, `/${encodeURIComponent(accountId)}/permissions`);
  await assertOk(response);
  return response.json();
};

export const addAccountPermission = async (accountId, group, ctx) => {
  debug(ctx, "[addAccountPermission]", accountId, group);
  assertIntegrator(ctx);
  const response = await accountsOpsProxy(ctx, `/tools/group`, METHODS.POST, {
    group_name: group,
    element: accountId,
    element_type: "account_id",
  });
  await assertOk(response);
  return response.json();
};

export const deleteAccountPermission = async (accountId, group, ctx) => {
  debug(ctx, "[deleteAccountPermission]", accountId, group);
  assertIntegrator(ctx);
  const response = await accountsOpsProxy(ctx, `/tools/group`, METHODS.DELETE, {
    group_name: group,
    element: accountId,
    element_type: "account_id",
  });
  await assertOk(response);
  return response.json();
};

export const changeUsername = async (accountId, username, ctx) => {
  debug(ctx, "[changeUsername]", accountId, username);
  assertIntegrator(ctx);
  const response = await accountsOpsProxy(
    ctx,
    `/${encodeURIComponent(accountId)}/user-info/username`,
    METHODS.PUT,
    {
      username,
      username_repeat: username,
    },
  );
  await assertOk(response);
  return { status: "Success" };
};

export const changeEmail = async (accountId, email, verified, ctx) => {
  debug(ctx, "[changeEmail]", accountId, email, verified);
  assertIntegrator(ctx);
  const response = await accountsOpsProxy(
    ctx,
    `/${encodeURIComponent(accountId)}/user-info/email/change`,
    METHODS.POST,
    {
      email,
      email_repeat: email,
      email_types: ["primary_contact"],
      verified,
      verify_url: "https://lindenlab.com/change_email/verify/{{.Nonce}}",
    },
  );
  await assertOk(response);
  return response.json();
};

// no header required
export const getToolsGroups = async (ctx) => {
  debug(ctx, "[getToolsGroups]");
  const response = await accountsOpsProxy(ctx, `/tools/group`);
  await assertOk(response);
  return response.json();
};

// no header required
export const getToolsPermissions = async (ctx) => {
  debug(ctx, "[getToolsPermissions]");
  const response = await accountsOpsProxy(ctx, `/tools/permission`);
  await assertOk(response);
  return response.json();
};

export const getClientsByIntegrator = async (integrator, ctx) => {
  // API will only return clients for the integrator specified in  x-tilia-integrator header
  const updatedCtx = ctxWithIntegrator(integrator, ctx);
  debug(updatedCtx, "[getClientsByIntegrator]", updatedCtx);
  const response = await accountsPubProxy(updatedCtx, "/v1/clients");
  await assertOk(response);
  return response.json();
};

// no header required
export const getClient = async (clientId, ctx) => {
  debug(ctx, "[getClient]", clientId);
  const response = await accountsOpsProxy(ctx, `/v1/client/${encodeURIComponent(clientId)}`);
  await assertOk(response);
  return response.json();
};

export const deleteClient = async (client, ctx) => {
  debug(ctx, "[deleteClient]", client.client_id);
  const ctxWithInt = ctxWithIntegrator(client.integrator, ctx);
  assertIntegrator(ctxWithInt);
  const response = await accountsOpsProxy(
    ctxWithInt,
    `/${client.account_id}/client-app/${encodeURIComponent(client.client_id)}`,
    METHODS.DELETE,
  );
  return buildTiliaApiResponse(response);
};

// no header required
export const getAllScopes = async (ctx) => {
  debug(ctx, "[getAllScopes]");
  const response = await accountsOpsProxy(ctx, `/v1/scopes`);
  await assertOk(response);
  return response.json();
};

// no header required
export const getClientScopes = async (clientId, ctx) => {
  debug(ctx, "[getClientScopes]", clientId);
  const response = await accountsOpsProxy(
    ctx,
    `/v1/clients/${encodeURIComponent(clientId)}/scopes`,
  );
  await assertOk(response);
  return response.json();
};

// no header required
export const addClientScope = async (clientId, scopeName, tokenType, isPublic, ctx) => {
  debug(ctx, "[addClientScope]", clientId, scopeName, tokenType, isPublic);
  const response = await accountsOpsProxy(ctx, `/v1/clients/scopes`, METHODS.POST, {
    client_id: clientId,
    token_type: tokenType,
    scope_name: scopeName,
    public: isPublic,
  });
  await assertOk(response);
  return response.json();
};

// no header required
export const deleteClientScope = async (scopeId, ctx) => {
  debug(ctx, "[deleteClientScope]", scopeId);
  const response = await accountsOpsProxy(
    ctx,
    `/v1/clients/scopes/${encodeURIComponent(scopeId)}`,
    METHODS.DELETE,
  );
  await assertOk(response);
  return response.json();
};

const messageMap = {
  INVALID_LENGTH: "Password is an invalid length",
  INVALID_FORMAT: "Password has an invalid format",
  OLD_PASSWORD_MATCHES_NEW: "New password must be different than the current password",
};

const toError = (error) => {
  // if error is not defined in the map, fallback to the key itself
  return new Error(messageMap[error] || error);
};

// attempts to return a reasonable error message from the API response
const convertToChangePasswordErrors = async (response) => {
  const responseJson = await response.json();

  const passwordErrorKeys = R.path(["payload", "errors", "password"], responseJson);
  const passwordErrors = R.map(toError, passwordErrorKeys);
  if (!isNilOrEmpty(passwordErrors)) return passwordErrors;

  // default, if the error comes back in a surprising structure
  return response.statusText;
};

// no header required?
export const changePassword = async (
  accountId,
  oldPassword,
  newPassword,
  newPasswordRepeat,
  ctx,
) => {
  debug(ctx, "[changePassword]", accountId); // no logging passwords
  const response = await accountsOpsProxy(
    ctx,
    `/${encodeURIComponent(accountId)}/user-info/password`,
    METHODS.PUT,
    {
      old_password: oldPassword,
      password: newPassword,
      password_repeat: newPasswordRepeat,
    },
  );

  if (!response.ok) {
    const errors = await convertToChangePasswordErrors(response);
    throw new MultiError(errors);
  }

  return response.json();
};
