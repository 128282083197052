import React, { createContext, useState, useContext, useEffect } from "react";
import * as R from "ramda";
import { useLocalStorage } from "usehooks-ts";
import * as toolsWebApi from "api/toolsWeb";
import * as utils from "utils";
import * as P from "context/permissions";
import detailsActionMenuKeys from "components/Account/detailsActionMenuKeys";

import { FullPageSpinner } from "components/Spinner";

const TokenContext = createContext();
export const TokenProvider = (props) => {
  const [sessionJWT, setAgentStorage] = useLocalStorage("tools:token");

  const deleteAgentStorage = () => {
    setAgentStorage(null);
  };

  return (
    <TokenContext.Provider
      value={{ sessionJWT, setToken: setAgentStorage, deleteToken: deleteAgentStorage }}
      {...props}
    />
  );
};

export const useToken = () => useContext(TokenContext);

//
//-----------------------------------------------------------------------
//
const defaultIntegratorResources = [
  P.SEARCH_RESOURCE,
  P.WEBHOOKS_RESOURCE,
  P.DETAILS_RESOURCE,
  P.PAYMENTS_RESOURCE,
  P.TRANSACTIONS_RESOURCE,
  P.KYC_BASIC_RESOURCE,
];

const defaultMenuSettings = [
  detailsActionMenuKeys.NOTE,
  detailsActionMenuKeys.BLOCK,
  detailsActionMenuKeys.UNBLOCK,
  detailsActionMenuKeys.USERNAME,
  detailsActionMenuKeys.CREATE_ENTITY,
  detailsActionMenuKeys.EDIT_PAYOUT_SETTINGS,
];

const defaultIntegratorConfig = {
  getAdminLink: () => null,
  logoFile: null,
  ownsPassword: false,
  menuSettings: defaultMenuSettings,
};

const buildFinalAccessResources = ({ addResources = [], removeResources = [] }) => {
  const defaultPlusAdded = R.uniq([...defaultIntegratorResources, ...addResources]);
  return R.without(removeResources, defaultPlusAdded);
};

const buildIntegrators = R.mapObjIndexed((rawConfig, key) => {
  const config = rawConfig || {};
  const finalAllowedResources = buildFinalAccessResources(config);
  return {
    // introducing the idea of an "integratorId" which can be different from the display name
    integratorId: key,
    // defaults
    ...defaultIntegratorConfig,
    // default displayName to the key, should get clobbered by config
    displayName: key,
    // values from redis
    ...config,
    // this cannot be in the default as it needs access to the finalAllowedResources
    accessResource: (resource) => {
      return (
        utils.isNilOrEmpty(resource) ||
        R.includes(resource, finalAllowedResources) ||
        R.includes("all", finalAllowedResources)
      );
    },
  };
});

// the integrator(s) an agent can access is determined by their token
// - if token.integrator === tilia: access to all
// - else : access to specific integrator only
//
// this provider will be in play for unauthenticated routes, so null / no token is a valid state
// this provider will not render children while processing a token change and updating available integrators. this allows children to access integrator data synchronously
//
const IntegratorContext = createContext();

export const IntegratorProvider = (props) => {
  const { sessionJWT } = useToken();

  const [lastSessionJWT, setLast] = useState(null);

  // if new, non-null session is different than last session, spin and reload
  const nullSession = utils.isNilOrEmpty(sessionJWT);
  const sessionDiffersFromLastSession = sessionJWT !== lastSessionJWT;
  const reload = !nullSession && sessionDiffersFromLastSession;

  const [loading, setLoading] = useState(true);
  const [integrators, setIntegrators] = useState([]);

  useEffect(() => {
    const getAvailableIntegrators = async (token) => {
      if (token.integrator === "tilia") {
        const integratorConfigs = await toolsWebApi.getIntegrators();
        return buildIntegrators(integratorConfigs);
      } else {
        // non-tilia integrator
        const config = await toolsWebApi.getIntegratorConfig(token.integrator);
        return buildIntegrators({ [token.integrator]: config });
      }
    };

    const asyncFn = async () => {
      setLoading(true);
      const token = utils.decodeToken(sessionJWT);
      const integrators = await getAvailableIntegrators(token);
      setIntegrators(integrators);
      setLoading(false);
      setLast(sessionJWT);
    };

    if (utils.exists(sessionJWT)) {
      asyncFn();
    } else {
      setIntegrators([]);
      setLoading(false);
    }
  }, [sessionJWT]);

  if (loading || reload) return <FullPageSpinner />;
  return <IntegratorContext.Provider value={{ integrators }} {...props} />;
};

export const useIntegrators = () => useContext(IntegratorContext);

export const useIntegrator = (key) => {
  const { integrators } = useIntegrators();
  if (utils.isNilOrEmpty(key)) return null;
  // the integrator values in the Redis global "integrator" key are all lower-case
  key = key.toLowerCase();
  return integrators[key];
};
