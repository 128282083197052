/* eslint-disable no-console */
import * as R from "ramda";
import { Base64 } from "js-base64";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import duration from "dayjs/plugin/duration.js";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);

export const debug =
    (...pre) =>
    (...args) => {
        console.log("\nDEBUG:", ...pre, ...args, "\n");
    };

export class ResponseError extends Error {
    constructor(statusCode, loggingMessage, ...params) {
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ResponseError);
        }
        this.name = "ResponseError";
        this.statusCode = statusCode;
        this.loggingMessage = loggingMessage;
    }
}

//---------------------------------------------
//
export const TILIA = "tilia";

export const noop = () => {};
export const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);
export const exists = (v) => !isNilOrEmpty(v);
export const isFunction = R.pipe(R.type, R.identical("Function"));
export const isArray = R.is(Array);
export const isNotArray = R.complement(isArray);
export const ensureArray = R.when(isNotArray, R.of(Array));
export const isString = R.is(String);
export const mapIndex = R.addIndex(R.map);
const isBool = R.compose(R.equals("Boolean"), R.type);
export const isBooleanTrue = R.allPass([isBool, R.equals(true)]);
export const isTrueStringIgnoreCase = R.ifElse(R.is(String), R.compose(R.equals("true"), R.toLower), R.equals(true));
// returns true if input is string 'true' or boolean true, otherwise false
export const isTrue = R.anyPass([isBooleanTrue, isTrueStringIgnoreCase]);
export const isFalse = R.complement(isTrue);

export const isNumber = (value) => {
    if (typeof value === "boolean") {
        return false;
    }
    if (exists(value)) {
        return !Number.isNaN(Number(value));
    } else {
        return false;
    }
};

export const decodeToken = (accessToken) => {
    if (isNilOrEmpty(accessToken)) {
        return accessToken;
    }
    const encodedPart = R.pipe(R.split("."), R.prop(1))(accessToken);
    if (isNilOrEmpty(encodedPart)) return accessToken;

    const str = Buffer.from(encodedPart, "base64").toString();
    if (isNilOrEmpty(str)) return accessToken;

    return JSON.parse(str);
};

export const getTokenFromJWT = (sessionJWT) => {
    if (isNilOrEmpty(sessionJWT)) return {};
    return JSON.parse(Base64.decode(sessionJWT.split(".")[1]));
};

/*
  these are helper functions that assist with
  serializing and deserializing objects. they are meant
  to be used to store and fetch objects in the querystring
*/
export const toJSONB64 = (obj) => Base64.encode(JSON.stringify(obj));
export const fromB64JSON = (str) => {
    try {
        const queryJSON = Base64.decode(str);
        return JSON.parse(queryJSON);
    } catch {
        return {};
    }
};

//-------------------------------------------------
// Dates

export const isBeforeNow = (date) => {
    if (!date) return false;
    const d = dayjs(date);
    if (!d.isValid()) return false;
    return d.isBefore(dayjs());
};

export const getMillis = (dateStr) => {
    if (!dateStr) return dateStr;
    const d = dayjs(dateStr);
    if (!d.isValid()) return dateStr;
    return d.valueOf();
};

export const formatRelativePast = (dateStr, opts) => {
    if (!dateStr) return dateStr;
    const d = dayjs.utc(dateStr).local();
    if (!d.isValid()) return dateStr;
    return d.fromNow(opts);
};

export const formatDistanceToNow = (dateStr) => {
    if (!dateStr) return dateStr;
    const d = dayjs(dateStr);
    if (!d.isValid()) return dateStr;
    return d.fromNow();
};

export const formatDiffDuration = (startDate, endDate = Date.now()) => {
    if (!startDate) return 0;
    const s = dayjs(startDate);
    const e = dayjs(endDate);
    if (!s.isValid() || !e.isValid()) return 0;
    return dayjs.duration(s.diff(e)).format("HH:mm:ss");
};

export const daysToSeconds = (days) => {
    if (isNilOrEmpty(days)) return 0;
    return days * 24 * 60 * 60;
};

const format = (formatString) => (date) => {
    if (!date) return date;
    const d = dayjs(date);
    if (!d.isValid()) return date;
    return d.format(formatString);
};
export const formatDate = format("YYYY-MM-DD");
export const formatDateTime = format("YYYY-MM-DD HH:mm:ss");
export const formatDateTimeFilename = format("YYYY-MM-DD-HH-mm-ss");
export const formatDateTimeMillis = format("YYYY-MM-DD HH:mm:ss.SSS");
export const formatTime = format("HH:mm:ss");
