import React, { useEffect } from "react";
import "./userInteractionMonitoring";
import { ErrorBoundary, FullPageError } from "components/ErrorBoundary";
import { Helmet } from "./Helmet";
import { ROUTE_BASENAME } from "utils/environment";
import { BrowserRouter, useLocation, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Providers } from "./Providers";
import { theme, GlobalStyle } from "styles";
import { ScrollToTop } from "./ScrollToTop";
import { useAgent } from "context/agentContext";
import { Unauth } from "./Unauth";
import styled from "styled-components/macro";
import { getSessionJWT, isTokenExpired } from "utils/auth";
import { getTokenFromJWT, exists } from "utils";
import "json-bigint-patch";
import {
  AccountDetails,
  Clients,
  Diagnostics,
  Entity,
  Home,
  Info,
  KYC,
  Login,
  Logout,
  NotFound,
  Payouts,
  Permissions,
  ProductCatalogPage,
  Publisher,
  PublisherDetails,
  Search,
  Settings,
  Watchlist,
  Webhooks,
  TransactionDetails,
  UserProvisioning,
} from "../Pages";
import FinanceRoutes from "../Pages/Finance/FinanceRoutes";
import { AuthLayout } from "./AuthLayout";
import { SelectMenuPortal } from "components/Forms";
import { FullPageSpinner } from "components/Spinner";

const Error = () => {
  throw new Error();
};

const Content = styled.div`
  min-height: calc(100vh - 61px);
  background: ${theme("colors.pageBg")};
  color: ${theme("colors.pageFg")};
`;

const removeJWT = (location) => {
  const params = new URLSearchParams(location.search);
  params.delete("jwt");
  location.search = `?${params.toString()}`;
  return location;
};

const Authorized = () => {
  const { setAgent, agent, logoutAgent } = useAgent();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // check for possible post-login JWT, set agent and go
    if (!agent) {
      const sessionJWT = getSessionJWT(location.search);
      if (exists(sessionJWT)) {
        const token = getTokenFromJWT(sessionJWT);
        if (!isTokenExpired(token)) {
          setAgent(sessionJWT);
          navigate(removeJWT(location), { replace: true });
        } else {
          // no agent, sessionJWT exists in URL, but expired => go to login with redirect
          navigate(`/login?r=${encodeURIComponent(window.location.href)}`);
        }
      } else {
        // no agent, no sessionJWT in URL => go to login with redirect
        navigate(`/login?r=${encodeURIComponent(window.location.href)}`);
      }
    }
  }, [location, navigate, setAgent, agent]);

  if (agent) {
    if (!agent.hasToolsPermissions()) {
      return <Unauth>You are not authorized to access this application.</Unauth>;
    } else if (agent.tokenExpired()) {
      const integrator = agent.integrator;
      logoutAgent();
      return <Navigate to={`/login/${integrator}?r=${encodeURIComponent(window.location.href)}`} />;
    }
    // happy path, agent exists/token/perms., all systems go
    return <AuthLayout />;
  } else {
    // spin while the effect runs
    return <FullPageSpinner />;
  }
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="login/*" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="error" element={<Error />} />
      <Route element={<Authorized />}>
        <Route index element={<Navigate to="dashboard" replace={true} />} />
        <Route path="dashboard" element={<Home />} />
        <Route path="permissions/*" element={<Permissions />} />
        <Route path="watchlist/*" element={<Watchlist />} />
        <Route path="info" element={<Info />} />
        <Route path="logout" element={<Logout />} />
        <Route path="search" element={<Search />} />
        <Route path="kyc" element={<KYC />} />
        <Route path="account/:accountId/*" element={<AccountDetails />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="entity/:entityId" element={<Entity />} />
        <Route path="webhooks" element={<Webhooks />} />
        <Route path="clients/*" element={<Clients />} />
        <Route path="finance/*" element={<FinanceRoutes />} />
        <Route path="payouts" element={<Payouts />} />
        <Route path="diagnostics" element={<Diagnostics />} />
        <Route path="publisher" element={<Publisher />} />
        <Route path="publisher/:publisherId/*" element={<PublisherDetails />} />
        <Route path="product-catalog" element={<ProductCatalogPage />} />
        <Route path="user-provisioning" element={<UserProvisioning />} />
        <Route path="transaction/:transactionId" element={<TransactionDetails />} />
        <Route path="error" element={<Error />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export const App = ({ basename = ROUTE_BASENAME }) => {
  return (
    <ErrorBoundary FallbackComponent={FullPageError}>
      <Helmet />
      <BrowserRouter basename={basename}>
        <Providers>
          <GlobalStyle />
          <ScrollToTop />
          <Content>
            <AppRoutes />
          </Content>
        </Providers>
      </BrowserRouter>
      <SelectMenuPortal />
    </ErrorBoundary>
  );
};
