import React, { useEffect, useRef, useState } from "react";
import "styled-components/macro";
import { CheckmarkIcon, CopyIcon } from "components/Icons";
import { IconButton, OutlineButton } from "components/Button";
import { theme } from "styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isNilOrEmpty } from "utils";

export const CopyButton = ({ text, onClick = defaultOnClick }) => {
  const { handleCopy, copied } = useCopyTimer();
  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <OutlineButton disabled={isNilOrEmpty(text)} onClick={onClick}>
        {copied ? "Copied!" : "Copy"}
      </OutlineButton>
    </CopyToClipboard>
  );
};

const defaultOnClick = (e) => e.stopPropagation();

const useCopyTimer = () => {
  const isMounted = useRef(true);
  // set isMounted to false on unmount of component
  useEffect(() => () => (isMounted.current = false), []);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      if (isMounted.current) {
        setCopied(false);
      }
    }, 800);
  };
  return { handleCopy, copied };
};

const CopyCheck = ({ text, onCopy, copied, onClick }) => {
  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <IconButton data-testid="copy-icon-button" onClick={onClick}>
        {copied ? (
          <CheckmarkIcon size="1.2em" data-testid="check-icon" />
        ) : (
          <CopyIcon size="1.2em" data-testid="copy-icon" />
        )}
      </IconButton>
    </CopyToClipboard>
  );
};

const Copier = ({ value, onClick = defaultOnClick }) => {
  const { handleCopy, copied } = useCopyTimer();
  return (
    <span>
      <span
        className="copier"
        css={`
          visibility: hidden;
          background: ${theme("colors.pageBg")};
        `}
      >
        <CopyCheck text={value} onCopy={handleCopy} copied={copied} onClick={onClick} />
      </span>
    </span>
  );
};

// Requires a non-null text prop as the copyable value.
// If optional children are passed, they are rendered in lieu of the text.
export const CopyableText = ({ text, children, ...props }) => {
  if (isNilOrEmpty(text)) return null;
  return (
    <span
      css={`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:hover .copier {
          visibility: visible;
        }
      `}
      data-testid="copyable-text"
      {...props}
    >
      <span
        css={`
          display: inline-block;
          margin-right: 4px;
        `}
      >
        {children || text}
      </span>
      <Copier value={text} />
    </span>
  );
};

export const Id = ({ id, children, ...props }) => (
  <CopyableText text={id} {...props}>
    {children}
  </CopyableText>
);
