import {
  ModalFooter as ChakraModalFooter,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { MultiError, getErrorFromResponse } from "api/utils";
import { ButtonIconWrapper, ButtonSpinner, PrimaryButton, TextLinkButton } from "components/Button";
import { Formik } from "formik";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { STATUS, exists, noop } from "utils";
import ModalErrors from "./ModalErrors";

const InnerFormModal = ({ validateForm, children }) => {
  useEffect(() => {
    (() => validateForm())();
  }, [validateForm]);
  return children;
};

export default function useFormModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const FormModal = ({
    actionIcon,
    actionText,
    children,
    formProps,
    onSubmitActionSuccess,
    onSubmitActionFailed,
    submitAction,
    testIdPrefix = "form-modal-",
    titleText,
    disableActionButton = false,
    disableTillEdit,
    closeOnSubmitSuccess = true,
    onCloseAction,
    size = "xl",
  }) => {
    const [actionState, setActionState] = useState({ status: STATUS.PRE_INIT });

    const handleSubmit = async (...args) => {
      setActionState(R.assoc("status", STATUS.INITIALIZED));

      try {
        const response = await submitAction(...args);
        if (response.status === "Success") {
          setActionState(R.assoc("status", STATUS.SUCCESS));
          onSubmitActionSuccess();
          closeOnSubmitSuccess && onClose();
        } else {
          setActionState(R.assoc("status", STATUS.ERROR));
          onSubmitActionFailed && onSubmitActionFailed();
          setActionState((state) => ({
            ...state,
            status: STATUS.ERROR,
            error: getErrorFromResponse(response),
          }));
        }
      } catch (err) {
        const error = err instanceof MultiError ? err.errors : err;
        setActionState(R.assoc("status", STATUS.ERROR));
        onSubmitActionFailed && onSubmitActionFailed();
        setActionState((state) => ({
          ...state,
          status: STATUS.ERROR,
          error,
        }));
      }
    };
    const closeAll = () => {
      onClose();
      exists(onCloseAction) && onCloseAction();
    };

    return (
      <Formik {...formProps} onSubmit={handleSubmit}>
        {({ handleSubmit, isValid, validateForm, _isSubmitting, dirty }) => {
          const errorFooter =
            actionState.status === STATUS.ERROR ? <ModalErrors errors={actionState.error} /> : null;

          const cancelButton =
            actionState.status === STATUS.INITIALIZED ? (
              <TextLinkButton disabled={true} onClick={noop}>
                Cancel
              </TextLinkButton>
            ) : actionState.status === STATUS.SUCCESS ? (
              <PrimaryButton data-testid={`${testIdPrefix}cancel-button`} onClick={closeAll}>
                Close
              </PrimaryButton>
            ) : (
              <TextLinkButton data-testid={`${testIdPrefix}cancel-button`} onClick={closeAll}>
                Cancel
              </TextLinkButton>
            );

          let actionButtonDisabled = true;
          let actionButton = null;
          if (submitAction) {
            actionButtonDisabled = disableTillEdit ? !(isValid && dirty) : !isValid;
            actionButton =
              actionState.status === STATUS.INITIALIZED ? (
                <PrimaryButton disabled={true} onClick={noop}>
                  {actionText}
                  <ButtonSpinner />
                </PrimaryButton>
              ) : actionState.status === STATUS.SUCCESS ? null : (
                <PrimaryButton
                  data-testid={`${testIdPrefix}submit-button`}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={actionButtonDisabled}
                >
                  {actionText}
                  {actionIcon && <ButtonIconWrapper>{actionIcon}</ButtonIconWrapper>}
                </PrimaryButton>
              );
          }

          return (
            <Modal size={size} isOpen={isOpen} onClose={closeAll}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{titleText}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {errorFooter}
                  <InnerFormModal validateForm={validateForm}>{children}</InnerFormModal>
                </ModalBody>
                <ChakraModalFooter gap={3}>
                  {cancelButton}
                  {!disableActionButton && actionButton}
                </ChakraModalFooter>
              </ModalContent>
            </Modal>
          );
        }}
      </Formik>
    );
  };
  return [FormModal, onOpen, onClose];
}
